import React from "react";

export const Email = () => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.86999 0H16.12C17.37 0 18 0.59 18 1.79V10.21C18 11.4 17.37 12 16.12 12H2.86999C1.61999 12 0.98999 11.4 0.98999 10.21V1.79C0.98999 0.59 1.61999 0 2.86999 0ZM9.48999 8.6L16.23 3.07C16.47 2.87 16.66 2.41 16.36 2C16.07 1.59 15.54 1.58 15.19 1.83L9.48999 5.69L3.79999 1.83C3.44999 1.58 2.91999 1.59 2.62999 2C2.32999 2.41 2.51999 2.87 2.75999 3.07L9.48999 8.6Z"
        fill="#F8A740"
      />
    </svg>
  );
};
