import React from "react";

export const Telegram = () => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.92 0.085478L0.747101 5.38029C-0.220143 5.75666 -0.214547 6.27941 0.56964 6.51251L4.2084 7.61221L12.6274 2.46609C13.0255 2.23144 13.3892 2.35767 13.0903 2.61478L6.26919 8.5787H6.26759L6.26919 8.57948L6.01818 12.2131C6.38589 12.2131 6.54817 12.0497 6.75441 11.8569L8.52182 10.1919L12.1982 12.8226C12.876 13.1843 13.3628 12.9984 13.5315 12.2147L15.9448 1.19602C16.1918 0.236493 15.5667 -0.197965 14.92 0.085478Z"
        fill="#F8A740"
      />
    </svg>
  );
};
