import React from "react";

export const Phone = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.58103 10.1975C12.1502 13.9756 12.9599 9.60478 15.2324 12.0093C17.4233 14.3284 18.6825 14.793 15.9067 17.7313C15.559 18.0272 13.3499 21.5867 5.58622 13.3686C-2.17838 5.14958 1.18142 2.80811 1.46092 2.44006C4.24346 -0.506357 4.67472 0.834679 6.8656 3.15379C9.13811 5.55931 5.01185 6.41945 8.58103 10.1975Z"
        fill="#F8A740"
      />
    </svg>
  );
};
