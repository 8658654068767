import React from "react";
import { styled } from "../../../../stitches.config";
import man from "../../../images/man.png";

const variants = [
  {
    text: "Перевіряємо юридичну чистоту,",
  },
  {
    text: "Рекомендуємо найкращий варіант,",
  },

  {
    text: "Торгуємося з продавцем, часто окупаючи наші послуги.",
  },
];

export const SaveYourTime = () => {
  return (
    <Section>
      <Container>
        <Image src={man} />
        <Box>
          <Title>Заощаджуємо ваш час, сили, нерви</Title>
          <List>
            {variants.map((el, i) => (
              <Item key={i}>{el.text}</Item>
            ))}
          </List>
        </Box>
      </Container>
    </Section>
  );
};

const Section = styled("section", {
  margin: "48px 16px 0",
  "@md": {
    margin: "146px 0 0",
  },
});

const Container = styled("div", {
  margin: "0 auto",
  maxWidth: 1170,
  "@md": {
    display: "flex",
    alignItems: "center",
    gap: 80,
  },
  "@lg": {
    gap: 110,
  },
});

const Image = styled("img", {
  position: "relative",
  display: "none",
  "@md": {
    display: "block",
    height: 400,
    borderRadius: 24,
    "&:after": {
      content: "1233232123",
      position: "absolute",
      bottom: 1000,
      left: 0,
      background: "$orange",
      borderRadius: "24px",
      width: 412,
      height: 408,
      zIndex: 1,
    },
  },
});

const Box = styled("div", {
  padding: 32,
  background: "$orange",
  borderRadius: 32,
  "@xxs": {
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
    flexDirection: "column",
    maxWidth: 768,
  },
  "@md": {
    margin: "0",
    display: "block",
    padding: 0,
    background: "none",
    borderRadius: 0,
  },
});

const Title = styled("h1", {
  margin: "0 0 12px",
  fontWeight: 400,
  fontSize: 28,
  lineHeight: "124%",
  letterSpacing: "0.0125em",
  color: "$black",
  "@md": {
    margin: "0 0 32px",
    fontSize: 32,
    maxWidth: 430,
  },
});

const List = styled("ul", {
  padding: "0 0 0 20px",
});

const Item = styled("li", {
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "25px",
  letterSpacing: "0.0125em",
  color: "$black",
  margin: "0 0 12px",
  "&:last-of-type": {
    margin: "0",
  },
});
