import React from "react";
import call from "../../../images/phonecall.svg";
import check from "../../../images/carcheck.svg";
import damage from "../../../images/cardamage.svg";
import { styled } from "../../../../stitches.config";

const ourWorks = [
  {
    icon: call,
    text: "Телефонуємо продавцям",
  },
  {
    icon: check,
    text: "Перевіряємо технічний стан",
  },
  {
    icon: damage,
    text: "Бракуємо хлам і авто після ДТП",
  },
];

export const TurnkeyCar = () => {
  return (
    <Section>
      <Container>
        <Title>
          <TitleBold>Авто під ключ</TitleBold> - це допомога в покупці авто з
          пробігом
        </Title>
        <Text>
          Ви повідомляєте свій бюджет і параметри автомобіля - Ми беремо на себе
          всі турботи
        </Text>
        <Box>
          {ourWorks.map((el, i) => (
            <Card key={i}>
              <Icon src={el.icon} />
              <CardText>{el.text}</CardText>
            </Card>
          ))}
        </Box>
      </Container>
    </Section>
  );
};

const Section = styled("section", {
  margin: "52px 16px 0",
  "@md": {
    margin: "120px 16px 0",
  },
});

const Container = styled("div", {
  maxWidth: 1160,
  margin: "0 auto",
});

const TitleBold = styled("h1", {
  margin: "0",
  fontWeight: 700,
  fontSize: 28,
  lineHeight: "124%",
  letterSpacing: "0.0125em",
  color: "$black",
  display: "inline",
  "@xxs": {
    textAlign: "center",
  },
  "@md": {
    fontSize: 32,
  },
});

const Title = styled("h1", {
  margin: "0 0 12px",
  fontWeight: 600,
  fontSize: 28,
  lineHeight: "124%",
  letterSpacing: "0.0125em",
  color: "$black",
  "@xxs": {
    maxWidth: "790px",
    textAlign: "center",
    margin: "0 auto 12px",
  },
  "@md": {
    fontSize: 32,
    margin: "0 auto 16px",
  },
});

const Text = styled("p", {
  margin: "0 0 24px",
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "144%",
  letterSpacing: "0.0125em",
  color: "$black",
  "@xxs": {
    textAlign: "center",
  },
  "@md": {
    fontSize: 16,
    lineHeight: "124%",
    margin: "0 0 40px",
  },
});

const Box = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 16,
  "@md": {
    flexDirection: "row",
    gap: 32,
  },
});

const Card = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: 16,
  background: "linear-gradient(283.63deg, #F1F3FC 0%, #F7F7FB 100%)",
  borderRadius: "24px",
  padding: 24,
  width: 342,
  "@md": {
    padding: 38,
    width: 365,
  },
});

const Icon = styled("img", {
  width: 45,
  height: 45,
});

const CardText = styled("p", {
  margin: 0,
  fontWeight: 700,
  fontSize: 20,
  lineHeight: "27px",
  letterSpacing: "0.0125em",
  color: "$black",
  textAlign: "center",
});
