import React from "react";
import { styled } from "../../../../stitches.config";
import search from "../../../images/car-search.svg";
import price from "../../../images/car-prices.svg";
import doc from "../../../images/car-document.svg";
import document from "../../../images/Benefits/doc.svg";
import day from "../../../images/Benefits/24.svg";
import person from "../../../images/Benefits/person.svg";
import personDoc from "../../../images/Benefits/person-doc.svg";
import certificate from "../../../images/Benefits/certificate.svg";
import cogs from "../../../images/Benefits/cogs.svg";
import carCheck from "../../../images/Benefits/carcheck.svg";
import messages from "../../../images/Benefits/messages.svg";
import personCar from "../../../images/Benefits/person-car.svg";
import eye from "../../../images/Benefits/eye-cog.svg";
import paint from "../../../images/Benefits/paint.svg";
import road from "../../../images/Benefits/road.svg";
import calendar from "../../../images/Benefits/calendar.svg";
import carSearch from "../../../images/Benefits/car-search.svg";

const benefits = [
  {
    image: doc,
    title: "Допомога в оформленні авто",
    options: [
      {
        icon: day,
        description: "Швидко та професійно - протягом 1-2 годин",
      },
      {
        icon: document,
        description:
          "Консультація за документами і особливостям перереєстрації",
      },
      {
        icon: person,
        description:
          "Допоможемо в оформленні як фізичним, так і юридичним особам",
      },
      {
        icon: personDoc,
        description:
          "Первинна реєстрація розмитнених і нових транспортних засобів",
      },
      {
        icon: certificate,
        description: "Допомога в сертифікації",
      },
    ],
  },
  {
    image: price,
    title: "Автопідбір: Підбір авто за параметрами",
    options: [
      {
        icon: document,
        description: "Працюємо офіційно, за договором",
      },
      {
        icon: carSearch,
        description: "Ми самі знаходимо варіанти",
      },
      {
        icon: cogs,
        description: "Перевіряємо технічно і юридчно",
      },
      {
        icon: carCheck,
        description: "Перевірка на СТО входить у вартість",
      },
      {
        icon: messages,
        description: "Трогуємося з продавцем",
      },
      {
        icon: personCar,
        description: "Супроводжуємо реєстрацію угоди в МРЕВ",
      },
    ],
  },
  {
    image: search,
    title: "Перевірка авто перед купівлею",
    options: [
      {
        icon: eye,
        description: "Проводимо огляд і діагностику в Києві",
      },
      {
        icon: paint,
        description: "Перевірка ЛКП",
      },
      {
        icon: road,
        description: "Перевіряємо авто на дорозі",
      },
      {
        icon: calendar,
        description:
          "Автоексперт на день: Огляд трьох автомобілів за один день",
      },
    ],
  },
];

export const Benefits = () => {
  return (
    <Container>
      <Wrapper>
        {benefits.map((el, i) => (
          <Box key={i}>
            <Image src={el.image} />
            <OptionBox>
              <Title>{el.title}</Title>
              {el.options.map((option, i) => (
                <Option>
                  <IconBox key={i}>
                    <Icon src={option.icon} />
                  </IconBox>
                  <Text>{option.description}</Text>
                </Option>
              ))}
            </OptionBox>
          </Box>
        ))}
      </Wrapper>
    </Container>
  );
};

const Container = styled("section", {
  padding: "48px 16px",
  background: "$benefitBg",
  "@lg": {
    padding: "82px 0",
  },
});

const Wrapper = styled("div", {
  maxWidth: 1070,
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  "@xs": {
    alignItems: "center",
  },
});

const Box = styled("div", {
  display: "flex",
  gap: 80,
  "&:not(:last-child)": {
    marginBottom: 32,
    "@lg": {
      marginBottom: 80,
    },
  },
  "&:nth-child(2n)": {
    "@lg": {
      flexDirection: "row-reverse",
    },
  },
});

const Image = styled("img", {
  display: "none",
  "@lg": {
    display: "block",
    width: 458,
  },
});

const OptionBox = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 20,
});

const Option = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: 20,
});

const IconBox = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 44,
  minHeight: 44,
  background: "$white",
  boxShadow: "0px 12px 32px rgba(169, 169, 173, 0.16)",
  borderRadius: 12,
});

const Icon = styled("img", {
  width: 24,
  height: 24,
});

const Title = styled("h1", {
  margin: "0 0 12px",
  fontWeight: 700,
  fontSize: 28,
  lineHeight: "124%",
  letterSpacing: "0.0125em",
  color: "$black",
  "@xs": {
    maxWidth: 440,
  },
  "@lg": {
    fontSize: 32,
    maxWidth: 500,
  },
});

const Text = styled("p", {
  margin: 0,
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "25px",
  color: "$black",
  "@xs": {
    maxWidth: 390,
  },
});
