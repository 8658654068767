import React from "react";
import logo from "../../../images/auto-logo.svg";
import { styled } from "../../../../stitches.config";
import { Facebook } from "../../../ui/common/Facebook";
import { Instagram } from "../../../ui/common/Instagram";
import { Telegram } from "../../../ui/common/Telegram";
import { Email } from "../../../ui/common/Email";

const socialMedia = [
  {
    icon: <Facebook />,
    link: "https://www.facebook.com/profile.php?id=100087513498001",
  },
  {
    icon: <Instagram />,
    link: "https://www.instagram.com/auto_podbor_vlad/?igshid=YmMyMTA2M2Y%3D",
  },
  {
    icon: <Telegram />,
    link: "https://t.me/+BGs94wZhnjA0ZjUy",
  },
  {
    icon: <Email />,
    link: "mailto:autopodborvlad@gmail.com",
  },
];

export const Navigation: React.FC = () => {
  return (
    <Header>
      <Container>
        <LogoBox>
          <Logo src={logo} alt="logo" />
          <Phone href="tel:+380977707573">+38 (097) 770 75 73</Phone>
        </LogoBox>
        <Box>
          <PhoneButton href="tel:+380977707573">
            +38 (097) 770 75 73
          </PhoneButton>
          <SocialBox>
            {socialMedia.map((el, i) => (
              <Button key={i} href={el.link} target="_blank">
                {el.icon}
              </Button>
            ))}
          </SocialBox>
        </Box>
      </Container>
    </Header>
  );
};

const Header = styled("header", {
  position: "sticky",
  top: 0,
  background: "$navigationBg",
  backdropFilter: "blur(6px)",
  zIndex: 5,
});

const Container = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 20,
  padding: "8px 16px",
  maxWidth: "1280px",
  margin: "0 auto",
  "@sm": {
    padding: "8px 32px",
  },
  "@lg": {
    padding: "20px",
  },
  "@xl": {
    padding: "20px 0",
  },
});

const SocialBox = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8,
});

const LogoBox = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 42,
});

const Phone = styled("a", {
  display: "none",
  "@lg": {
    textDecoration: "none",
    display: "block",
    cursor: "pointer",
    margin: 0,
    color: "$black",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "144%",
    letterSpacing: "0.01em",
    transition: "all 300ms ease",
  },
  "&:hover": {
    color: "$orange",
  },
});

const Box = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start",
  gap: 8,
});

const Button = styled("a", {
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 40,
  minHeight: 40,
  background: "$socialOrange",
  border: "none",
  borderRadius: "100%",
  cursor: "pointer",
  transition: "all 300ms ease",
  "&:hover": {
    background: "$orange",
    "&>svg": {
      "&>path": {
        transition: "all 300ms ease",
        fill: "$white",
      },
    },
  },
});

const PhoneButton = styled("a", {
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 40,
  minHeight: 40,
  width: "100%",
  background: "$socialOrange",
  border: "none",
  borderRadius: "200px",
  cursor: "pointer",
  padding: "4px 12px",
  color: "$orange",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "144%",
  letterSpacing: "0.01em",
  transition: "all 300ms ease",
  "&:hover": {
    color: "$white",
    background: "$orange",
  },
  "@lg": {
    display: "none",
  },
});

const Icon = styled("img", {
  height: 12,
  width: "auto",
});

const Logo = styled("img", {
  height: 48,
  width: "auto",
  cursor: "pointer",
});
