import React from "react";
import { styled } from "../../../../stitches.config";
import project1 from "../../../images/Projects/project1.png";
import project2 from "../../../images/Projects/project2.png";
import project3 from "../../../images/Projects/project3.png";
import project4 from "../../../images/Projects/project4.png";
import project5 from "../../../images/Projects/project5.png";
import project6 from "../../../images/Projects/project6.png";
import project7 from "../../../images/Projects/project7.png";
import project8 from "../../../images/Projects/project8.png";

const projects = [
  {
    image: project1,
  },
  {
    image: project2,
  },
  {
    image: project3,
  },
  {
    image: project4,
  },
  {
    image: project5,
  },
  {
    image: project6,
  },
  {
    image: project7,
  },
  {
    image: project8,
  },
];

export const OurProject = () => {
  return (
    <Section>
      <Container>
        <Title>Наші роботи</Title>
        <Box>
          {projects.map((el, i) => (
            <Image key={i} src={el.image} />
          ))}
        </Box>
      </Container>
    </Section>
  );
};

const Section = styled("section", {
  margin: "96px 0 0 24px",
  "@md": {
    marginTop: 146,
  },
});

const Container = styled("div", {
  margin: "0 auto",
  maxWidth: 1160,
});

const Title = styled("h1", {
  margin: "0 0 40px",
  color: "$black",
  fontWeight: 700,
  fontSize: 32,
  lineHeight: "124%",
  letterSpacing: "0.0125em",
  textAlign: "center",
});

const Box = styled("ul", {
  display: "flex",
  listStyle: "none",
  padding: 0,
  overflowX: "auto",
  overflowY: "hidden",
  margin: "4px 0",
  gap: 24,
  "@lg": {
    flexWrap: "wrap",
  },
});

const Image = styled("img", {
  width: "272px",
  "&:last-of-type": {
    marginRight: 24,
  },
  "@md": {
    "&:last-of-type": {
      marginRight: 0,
    },
  },
});
