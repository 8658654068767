import React from "react";
import { styled } from "../../../stitches.config";
import { useNoScroll } from "../../hooks/useNoScroll";
import telegram from "../../images/telegram-color.svg";
import viber from "../../images/viber-color.svg";
import exit from "../../images/exit.svg";
import { ModalSocial } from "../../types/ModalSocial";
import { Email } from "./Email";
import { Phone } from "./Phone";
import { Facebook } from "./Facebook";
import { Instagram } from "./Instagram";

const social: ModalSocial[] = [
  {
    icon: telegram,
    text: "Telegram",
    link: "https://t.me/+BGs94wZhnjA0ZjUy",
    newTab: true,
  },
  {
    icon: viber,
    text: "Viber",
    link: "viber://contact?number=%2B380663288782",
    newTab: true,
  },
  {
    icon: <Email />,
    text: "Email",
    link: "mailto:autopodborvlad@gmail.com",
    newTab: false,
  },
  {
    icon: <Phone />,
    text: "Телефон",
    link: "tel:+380977707573",
    newTab: false,
  },
];

const otherSocial: ModalSocial[] = [
  {
    icon: <Facebook />,
    text: "Facebook",
    link: "https://www.facebook.com/profile.php?id=100087513498001",
  },
  {
    icon: <Instagram />,
    text: "Instagram",
    link: "https://www.instagram.com/auto_podbor_vlad/?igshid=YmMyMTA2M2Y%3D",
  },
];

type Props = {
  onClose: () => void;
};

export const InformationModal: React.FC<Props> = ({ onClose }) => {
  useNoScroll();

  return (
    <AbsoluteContainer onClick={onClose}>
      <Exit src={exit} alt="exit" onClick={onClose} />
      <Container onClick={(event) => event.stopPropagation()}>
        <Title>Оберіть засіб</Title>
        <SocialBox>
          {social.map((el, i) => (
            <>
              {el.newTab ? (
                <Box key={i} href={el.link} target="_blank">
                  {typeof el.icon === "string" ? (
                    <img src={el.icon} />
                  ) : (
                    <>{el.icon}</>
                  )}
                  <Text>{el.text}</Text>
                </Box>
              ) : (
                <Box key={i} href={el.link}>
                  {typeof el.icon === "string" ? (
                    <img src={el.icon} />
                  ) : (
                    <>{el.icon}</>
                  )}
                  <Text>{el.text}</Text>
                </Box>
              )}
            </>
          ))}
        </SocialBox>
        <OtherSocial>
          {otherSocial.map((el, i) => (
            <Social href={el.link} target="_blank">
              {el.icon}
            </Social>
          ))}
        </OtherSocial>
      </Container>
    </AbsoluteContainer>
  );
};

const AbsoluteContainer = styled("div", {
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "$modalBgBlack",
  zIndex: 10,
});

const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  background: "$orange",
  borderRadius: 32,
  padding: 32,
  width: "90%",
  maxWidth: 585,
});

const SocialBox = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
  flexWrap: "wrap",
  marginBottom: 24,
});

const OtherSocial = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 16,
  flexWrap: "wrap",
});

const Social = styled("a", {
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 16,
  flexWrap: "wrap",
  background: "$socialWhiteWithOpacity",
  width: 40,
  height: 40,
  cursor: "pointer",
  transition: "all 300ms ease",
  borderRadius: "100%",
  "&>svg": {
    "&>path": {
      fill: "$white",
    },
  },
  "&:hover": {
    background: "$white",
    "&>svg": {
      "&>path": {
        transition: "all 300ms ease",
        fill: "$orange",
      },
    },
  },
});

const Exit = styled("img", {
  position: "fixed",
  top: 25,
  right: 25,
  cursor: "pointer",
  transition: "all 300ms ease",
  padding: 10,
  "&:hover": {
    transform: "rotate(90deg)",
  },
  "@md": {
    top: 50,
    right: 50,
  },
});

const Box = styled("a", {
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 8,
  background: "$white",
  width: 135,
  height: 84,
  borderRadius: 8,
  transition: "all 300ms ease",
  cursor: "pointer",
  "&:hover": {
    background: "$modalButtonHover",
  },
  "@xxs": {
    width: 186,
    height: 92,
  },
  "@xs": {
    width: 256,
    height: 124,
  },
});

const Text = styled("h1", {
  fontWeight: 700,
  fontSize: 18,
  lineHeight: "24px",
  color: "$black",
  margin: 0,
});

const Title = styled("h1", {
  fontWeight: 700,
  fontSize: 28,
  lineHeight: "124%",
  letterSpacing: "0.0125em",
  color: "$black",
  margin: "0 0 24px",
});
