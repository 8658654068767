import React from "react";

export const Facebook = () => {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 1.33333C7.33333 1.24493 7.29821 1.16014 7.2357 1.09763C7.17319 1.03512 7.08841 1 7 1H5.33333C4.49408 0.958195 3.6724 1.25026 3.04781 1.81239C2.42323 2.37451 2.04652 3.161 2 4V5.8H0.333333C0.244928 5.8 0.160143 5.83512 0.0976311 5.89763C0.035119 5.96014 0 6.04493 0 6.13333V7.86667C0 7.95507 0.035119 8.03986 0.0976311 8.10237C0.160143 8.16488 0.244928 8.2 0.333333 8.2H2V12.6667C2 12.7551 2.03512 12.8399 2.09763 12.9024C2.16014 12.9649 2.24493 13 2.33333 13H4.33333C4.42174 13 4.50652 12.9649 4.56904 12.9024C4.63155 12.8399 4.66667 12.7551 4.66667 12.6667V8.2H6.41333C6.48746 8.20107 6.55983 8.17739 6.61899 8.13272C6.67815 8.08804 6.72073 8.02492 6.74 7.95333L7.22 6.22C7.23326 6.17075 7.23505 6.11911 7.22521 6.06906C7.21538 6.01901 7.19419 5.97189 7.16328 5.93132C7.13236 5.89074 7.09255 5.85781 7.04691 5.83504C7.00127 5.81227 6.95101 5.80029 6.9 5.8H4.66667V4C4.68325 3.83499 4.76074 3.68208 4.88402 3.57113C5.00729 3.46018 5.16749 3.39917 5.33333 3.4H7C7.08841 3.4 7.17319 3.36488 7.2357 3.30237C7.29821 3.23986 7.33333 3.15507 7.33333 3.06667V1.33333Z"
        fill="#F8A740"
      />
    </svg>
  );
};
