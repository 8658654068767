import React from "react";
import { styled } from "../../../../stitches.config";
import main from "../../../images/vlad-photo.svg";
import mainEllipse from "../../../images/vlad-photo-ellipse.svg";

type Props = {
  setIsModalOpen: (value: boolean) => void;
};

export const MainBlock: React.FC<Props> = ({ setIsModalOpen }) => {
  return (
    <Container>
      <Wrapper>
        <Image src={main} />
        <Box>
          <Title>Автопідбір в Києві і області</Title>
          <Text>Підбираю автомобіль як для себе.</Text>
          <Text>
            Працюю офіційно: укладаємо договір на підбір авто під ключ.
          </Text>
          <Button onClick={() => setIsModalOpen(true)}>Залишити заявку</Button>
        </Box>
        <ImageDesktop src={mainEllipse} />
      </Wrapper>
    </Container>
  );
};

const Container = styled("section", {
  background: "$secondaryBackground",
  position: "relative",
  "@lg": {
    height: 590,
  },
});

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: 1280,
  margin: "0 auto",
  "@xs": {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "@lg": {
    flexDirection: "row",
  },
});

const Image = styled("img", {
  width: "auto",
  objectFit: "cover",
  maxHeight: 370,
  "@xs": {
    height: 340,
  },
  "@sm": {
    display: "none",
  },
});

const ImageDesktop = styled("img", {
  display: "none",
  "@sm": {
    display: "block",
    height: 450,
  },
  "@lg": {
    height: 590,
    position: "absolute",
    top: 0,
    right: 0,
  },
  "@xxl": {
    position: "initial",
  },
});

const Box = styled("div", {
  padding: "32px 16px 40px",
  "@lg": {
    margin: "160px 0 0",
  },
});

const Title = styled("h1", {
  margin: "0 0 16px",
  color: "$black",
  fontWeight: 600,
  fontSize: 40,
  lineHeight: "110%",
  letterSpacing: "0.0125em",
  "@lg": {
    fontSize: 64,
    maxWidth: 500,
  },
});

const Text = styled("p", {
  margin: "0 0 8px",
  color: "$black",
  "&:last-of-type": {
    margin: "0 0 32px",
  },
  "@lg": {
    maxWidth: 400,
  },
});

const Button = styled("button", {
  padding: "8px 32px",
  background: "$orange",
  color: "$white",
  fontWeight: 700,
  fontSize: 18,
  lineHeight: "32px",
  border: "none",
  borderRadius: 8,
  cursor: "pointer",
  transition: "all 300ms ease",
  "&:hover": {
    background: "$orangeButtonHover",
  },
});
