import React from "react";
import { styled } from "../../../../stitches.config";
import line1 from "../../../images/line1.svg";
import line2 from "../../../images/line2.svg";
import line3 from "../../../images/line3.svg";

type Props = {
  setIsModalOpen: (value: boolean) => void;
};

export const Request: React.FC<Props> = ({ setIsModalOpen }) => {
  return (
    <Section>
      <Container>
        <Title>Автопідбір в Києві і області</Title>
        <Text>Підбираю автомобіль як для себе.</Text>
        <Text>Працюю офіційно: укладаємо договір на підбір авто під ключ.</Text>
        <Button onClick={() => setIsModalOpen(true)}>Залишити заявку</Button>
        <FirstImage src={line3} />
        <SecondImage src={line2} />
        <ThirdImage src={line1} />
      </Container>
    </Section>
  );
};

const Section = styled("section", {
  margin: "114px 16px 80px",
  "@md": {
    margin: "114px 16px 118px",
  },
});

const FirstImage = styled("img", {
  display: "none",
  "@md": {
    position: "absolute",
    display: "block",
    top: 0,
    right: 0,
    width: 420,
  },
});

const SecondImage = styled("img", {
  display: "none",
  "@md": {
    position: "absolute",
    display: "block",
    top: 0,
    right: 0,
    width: 490,
  },
});

const ThirdImage = styled("img", {
  display: "none",
  "@md": {
    position: "absolute",
    display: "block",
    top: 0,
    right: 0,
    width: 540,
  },
});

const Container = styled("div", {
  position: "relative",
  margin: "0 auto",
  maxWidth: 1070,
  padding: 32,
  background: "$orange",
  borderRadius: 32,
  "@xxs": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 768,
  },
  "@md": {
    display: "block",
    padding: "40px 40px 46px",
    borderRadius: 40,
    maxWidth: 1070,
  },
});

const Title = styled("h1", {
  fontWeight: 700,
  fontSize: 32,
  lineHeight: "110%",
  margin: "0 0 16px",
  color: "$black",
  "@md": {
    maxWidth: 280,
  },
});

const Text = styled("p", {
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "144%",
  margin: "0 0 8px",
  color: "$black",
  "&:last-of-type": {
    margin: "0 0 32px",
  },
  "@md": {
    maxWidth: 460,
  },
});

const Button = styled("button", {
  padding: "8px 32px",
  background: "$white",
  color: "$black",
  fontWeight: 700,
  fontSize: 18,
  lineHeight: "32px",
  border: "none",
  borderRadius: 8,
  cursor: "pointer",
  transition: "all 300ms ease",
  width: "100%",
  maxWidth: 450,
  "&:hover": {
    background: "$modalButtonHover",
  },
  "@md": {
    maxWidth: 220,
  },
});
