import React, { useState } from "react";
import { InformationModal } from "../../ui/common/InformationModal";
import { AutoSelection } from "./components/AutoSelection";
import { Benefits } from "./components/Benefits";
import { MainBlock } from "./components/MainBlock";
import { OurProject } from "./components/OurProject";
import { Request } from "./components/Request";
import { SaveYourTime } from "./components/SaveYourTime";
import { TurnkeyCar } from "./components/TurnkeyCar";

export const Home: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <MainBlock setIsModalOpen={(value: boolean) => setIsModalOpen(value)} />
      <AutoSelection />
      <Benefits />
      <TurnkeyCar />
      <SaveYourTime />
      <OurProject />
      <Request setIsModalOpen={(value: boolean) => setIsModalOpen(value)} />
      {isModalOpen && (
        <InformationModal onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};
