import React, { ReactElement } from "react";
import { Helmet } from "gatsby-plugin-react-i18next";
import favicon from "../../images/auto-logo.svg";
import { Navigation } from "./Navigation/Navigation";
import { Footer } from "./Footer/Footer";

type Props = {
  children: ReactElement;
};

export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Helmet>
        <link id="favicon-icon" rel="icon" href={favicon} />
      </Helmet>
      <Navigation />
      {children}
      <Footer />
    </>
  );
};
