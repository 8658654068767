import React from "react";
import { styled } from "../../../../stitches.config";
import logo from "../../../images/logo-white.svg";
import { Email } from "../../../ui/common/Email";
import { Facebook } from "../../../ui/common/Facebook";
import { Instagram } from "../../../ui/common/Instagram";
import { Phone } from "../../../ui/common/Phone";
import { Telegram } from "../../../ui/common/Telegram";

const socialMedia = [
  {
    icon: <Facebook />,
    link: "https://www.facebook.com/profile.php?id=100087513498001",
  },
  {
    icon: <Instagram />,
    link: "https://www.instagram.com/auto_podbor_vlad/?igshid=YmMyMTA2M2Y%3D",
  },
  {
    icon: <Telegram />,
    link: "https://t.me/+BGs94wZhnjA0ZjUy",
  },
];

export const Footer: React.FC = () => {
  return (
    <FooterBlock>
      <Container>
        <Box>
          <Image src={logo} alt="logo" />
          <Text href="https://goo.gl/maps/eVhAaL8A2fD3xT7H9" target="_blank">
            Вишгород, вул. Глібова 39 індекс 07301
          </Text>
        </Box>
        <Box>
          <Button href="tel:+380977707573">
            <Phone />
            +38 (097) 770 75 73
          </Button>
          <Button href="mailto:autopodborvlad@gmail.com">
            <Email />
            autopodborvlad@gmail.com
          </Button>
        </Box>
        <Social>
          {socialMedia.map((el, i) => (
            <SocialButton key={i} href={el.link} target="_blank">
              {el.icon}
            </SocialButton>
          ))}
        </Social>
      </Container>
      <Link href="https://buildapps.pro/" target="_blank">
        © 2022 Розроблено BuildApps
      </Link>
    </FooterBlock>
  );
};

const FooterBlock = styled("footer", {
  background: "$black",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: 30,
  padding: 24,
  "@md": {
    padding: "80px 20px 64px",
  },
});

const Link = styled("a", {
  textDecoration: "none",
  fontSize: 12,
  color: "$gray",
  textAlign: "center",
  transform: "translateY(10px)",
  transition: "all 300ms ease",
  width: 200,
  margin: "0 auto",
  "@md": {
    transform: "translateY(40px)",
  },
  "&:hover": {
    color: "rgb(135, 74, 173)",
  },
});

const Container = styled("div", {
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 32,
  maxWidth: "1280px",
  "@md": {
    flexDirection: "row",
    gap: 180,
    justifyContent: "space-between",
  },
  "@lg": {
    width: 1240,
  },
  "@xl": {
    width: 1280,
  },
});

const Image = styled("img", {
  width: 120,
});

const Text = styled("a", {
  margin: 0,
  textDecoration: "none",
  color: "$white",
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "25px",
  letterSpacing: "0.0125em",
  textAlign: "center",
  maxWidth: 250,
});

const Box = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 24,
  "@md": {
    width: 250,
  },
});

const Button = styled("a", {
  textDecoration: "none",
  color: "$white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 14,
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "22px",
  transition: "all 300ms ease",
  "&>svg": {
    "&>path": {
      fill: "$white",
      transition: "all 300ms ease",
    },
  },
  "&:hover": {
    color: "$orange",
    "&>svg": {
      "&>path": {
        fill: "$orange",
      },
    },
  },
});

const Social = styled("div", {
  display: "flex",
  gap: 8,
  "@md": {
    width: 250,
    justifyContent: "flex-end",
  },
});

const SocialButton = styled("a", {
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 40,
  height: 40,
  background: "$socialOrange",
  border: "none",
  borderRadius: "100%",
  cursor: "pointer",
  transition: "all 300ms ease",
  "&:hover": {
    background: "$orange",
    "&>svg": {
      "&>path": {
        transition: "all 300ms ease",
        fill: "$white",
      },
    },
  },
});
