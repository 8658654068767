import { createStitches } from '@stitches/react';
import './src/styles/global.css';

export const { styled, css, getCssText } = createStitches({
  theme: {
    fonts: {
      montserrat: 'Montserrat, sans-serif',
    },
    colors: {
      white: '#ffffff',
      socialWhiteWithOpacity: 'rgba(255, 255, 255, 0.2)',
      black: '#1D1E21',
      gray: '#91959F',
      modalBgBlack: 'rgba(37, 36, 31, 0.9)',
      modalButtonHover: '#E3E3E3',
      navigationBg: 'rgba(255, 255, 255, 0.9)',
      orange: '#F8A740',
      orangeButtonHover: '#E4932B',
      socialOrange: 'rgba(248, 167, 64, 0.1)',
      secondaryBackground: "linear-gradient(283.63deg, #F1F3FC 0%, #F7F7FB 100%)",
      benefitBg: 'linear-gradient(283.63deg, #F1F3FC 0%, #F7F7FB 100%)',
    },
  },
  media: {
    xxs: '(min-width: 520px)',
    xs: '(min-width: 768px)',
    sm: '(min-width: 960px)',
    md: '(min-width: 1070px)',
    lg: '(min-width: 1280px)',
    xl: '(min-width: 1440px)',
    xxl: '(min-width: 1920px)',
  },
});