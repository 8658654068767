import React from "react";
import doc from "../../../images/doc.svg";
import car from "../../../images/car.svg";
import calendar from "../../../images/calendar.svg";
import { styled } from "../../../../stitches.config";
import line from "../../../images/line.svg";

const services = [
  {
    icon: doc,
    title: "Надішліть вимоги",
    text: "Отримайте швидку відповідь після дзвінка або листа з вимогами.",
  },
  {
    icon: calendar,
    title: "Отримайте Консультацію",
    text: "Підберемо авто, проконсультуємо по всім нюансам.",
  },
  {
    icon: car,
    title: "Допомога в Оформленні",
    text: "Допоможемо в оформленні як фізичним так і юридичним особам.",
  },
];

export const AutoSelection = () => {
  return (
    <Container>
      <Title>З турботою про Клієнтів</Title>
      <SubTitle>Автопідбір</SubTitle>
      <ServiceBox>
        {services.map((el, i) => (
          <Card>
            <Box>
              <IconBox>
                <Icon src={el.icon} />
              </IconBox>
              <CardTitle>{el.title}</CardTitle>
            </Box>
            <CardText>{el.text}</CardText>
          </Card>
        ))}
      </ServiceBox>
    </Container>
  );
};

const Container = styled("section", {
  margin: "48px 16px",
  maxWidth: 1170,
  "@xxs": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "@md": {
    margin: "130px auto 120px",
  },
});

const Title = styled("h1", {
  margin: "0 0 16px",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "110%",
  letterSpacing: "0.1225em",
  textTransform: "uppercase",
  color: "$gray",
});

const SubTitle = styled("h2", {
  margin: "0 0 40px",
  fontWeight: 600,
  fontSize: 32,
  lineHeight: "124%",
  letterSpacing: "0.0125em",
  color: "$black",
});

const ServiceBox = styled("div", {
  "@xxs": {
    margin: "0 auto",
  },
  "@md": {
    display: "flex",
    gap: 50,
  },
});

const Card = styled("div", {
  maxWidth: 600,
  display: "flex",
  flexDirection: "column",
  gap: 8,
  marginBottom: 24,
  "@md": {
    maxWidth: 340,
  },

  "&:nth-child(odd)": {
    "&>div": {
      "&>div": {
        boxShadow: "0px 12px 32px rgba(169, 169, 173, 0.16)",
      },
    },
  },

  "&:nth-child(1)": {
    "&>div": {
      "&>div": {
        "&>img": {
          width: 38,
          height: 38,
          marginTop: 10,
          marginLeft: 4,
          "@md": {
            marginTop: 26,
            width: 80,
            height: 80,
          },
        },
      },
    },
  },

  "&:nth-child(2)": {
    "&>div": {
      "&>div": {
        position: "relative",
        background:
          "radial-gradient(115.89% 115.89% at 49.61% 115.89%, #F8A73F 0%, #FCD8A9 100%)",
        boxShadow: "0px 16px 32px rgba(251, 216, 171, 0.45)",
        "@md": {
          "&:before": {
            content: "",
            position: "absolute",
            top: "50%",
            left: "-160%",
            background: `url(${line})`,
            width: 173,
            height: 2,
          },
          "&:after": {
            content: "",
            position: "absolute",
            top: "50%",
            left: "125%",
            background: `url(${line})`,
            width: 173,
            height: 2,
          },
        },
      },
    },
  },
});

const Box = styled("div", {
  display: "flex",
  gap: 16,
  alignItems: "center",
  "@md": {
    flexDirection: "column",
  },
});

const IconBox = styled("div", {
  background: "$white",
  boxShadow: "0px 12px 32px rgba(169, 169, 173, 0.16)",
  borderRadius: 7,
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@md": {
    borderRadius: 24,
    width: 128,
    height: 128,
  },
});

const Icon = styled("img", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 24,
  height: 24,
  "@md": {
    width: 54,
    height: 54,
  },
});

const CardTitle = styled("h2", {
  margin: 0,
  fontWeight: 700,
  fontSize: 20,
  lineHeight: "27px",
  letterSpacing: "0.0125em",
  color: "$black",
});

const CardText = styled("p", {
  margin: 0,
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "144%",
  color: "$black",
  "@md": {
    textAlign: "center",
  },
});
